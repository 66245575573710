.fpr-statuses {
  display: flex;
  flex-direction: row;
  min-width: 4rem;
  flex-wrap: wrap;

  .fpr-pending,
  .fpr-failed {
    font-size: 0.7rem;
  }

  .fpr-pending {
    color: grey;
    fill: grey;
  }
  .fpr-failed {
    color: red;
    fill: red;
  }
}
