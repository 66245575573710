.table-actions {
  white-space: nowrap;

  .table-action-buttons {
    min-height: 3rem;
    min-width: 3rem;

    &.MuiButton-disabled {
      opacity: 0.2;
    }
  }

  .table-action-buttons-view {
    white-space: nowrap;
  }
}

.table-action-menu {
  .table-action-menu-item {
    padding: 0;

    button {
      justify-content: left;
      width: 100%;

      & .MuiButton-disabled {
        opacity: "0.5";
      }

      svg {
        font-size: 2rem !important;
        fill: inherit;
      }
    }
  }
}

.dark {
  .table-action-menu {
    .table-action-menu-item {
      button {
        svg {
          fill: #ffffff;
        }
      }
    }
  }
}

.MuiTable-root .MuiOutlinedInput-notchedOutline {
  border-width: 0 0 1px 0;
}
