.dark {
  .survey-details-so-wrapper {
    background-color: #303030;
  }
}

.light {
  .survey-details-so-wrapper {
    background-color: #fafafa;
  }
}

.survey-details-so-wrapper {
  
  .root {
    flex-grow: 1;
  }

  .paper {
    padding: 2rem;
    color: red;
  }

  .survey-so-dialog {
    background-color: #303030;
    & header {
      & .MuiToolbar-root {
        justify-content: space-between;
      }
    }
  }

  .notes {
    min-height: 5rem;
    overflow: auto;
    font-family: sans-serif;
  }

  .post-qa-buttons {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem 0;
    & Button {
      display: flex;
    }
  }
}
