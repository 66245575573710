@import "../../../config/theme/themeColors.scss";

:root {
  .slider-container {
    .label {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .buttons {
      display: flex;
      align-items: center;
    }

    .close-icon {
      .MuiSvgIcon-root {
        fill: $error;
        font-size: 1.8rem;
        //NOTE: Added for dark-mode
        background-color: white;
        border-radius: 15px;
      }
    }

    .info-icon {
      fill: $info;
      font-size: 1.8rem;
    }

    &.default-slider {
      .MuiSlider-root {
        color: $info;
      }
    }

    .association-select {
      padding-bottom: 1rem;
    }
  }
}

.association-selection {
  .MuiAutocomplete-root {
    // have to use !important because MUI adds width to element via JS
    width: 100% !important;
  }
}

//CSS Applies only to ChartViewType scattersWithSlider
.charts-sliders-view {
  .MuiGrid-root {
    .MuiGrid-item {
      min-height: 0;
    }
    .bottom-drawer-header {
      .title {
        padding-left: 7rem;
        align-self: center;
      }
      .float-right {
        float: right;
      }
    }
  }

  .slider {
    padding-bottom: 1rem;
    .paper-slider {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .values-container {
      display: flex;
      .values {
        align-self: center;
      }
    }
  }
  //Slider positioning is left styleless
  //that way parent component has complete control over looks
  .bottom-drawer-content {
    .plots {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .add-range {
      padding-top: 2rem;
      padding-bottom: 2rem;
      padding-left: 1rem;
    }
  }
}
