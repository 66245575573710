/* Main styles */
@import url(https://fonts.googleapis.com/css?family=Orbitron&display=swap);

iframe {
  display: none;
}

$breakpoints: (
  "small": 767px,
  "medium": 992px,
  "large": 1200px,
);

@mixin respond-to($breakpoint) {
  // Retrieves the value from the key
  $value: map-get($breakpoints, $breakpoint);

  // If the key exists in the map
  @if $value !=null {
    // Prints a media query based on the value
    @media (min-width: $value) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Please make sure it is defined in `$breakpoints` map.";
  }
}

body {
  margin: 0;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

/**
 * Grid layout for all breakpoints
 */
.grid-container {
  align-content: start;
  display: grid;
  grid-column-gap: 0;
  height: 100%;
}

body {
  div[class$="-so-wrapper"] {
    padding: 1rem;
    width: 100%;

    @include respond-to("large") {
      padding: 2rem;
    }
  }

  div[class$="-so-dialog"] {
    .MuiToolbar-root {
      justify-content: space-between;
    }
  }

  /* @TODO remove once JIRA issues button removed */
  #atlwdg-trigger {
    z-index: 10000;
  }
}

.App {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
  }

  .print {
    display: none;
  }

  .branded {
    font-family: Orbitron;
  }

  @media print {
    .print {
      display: block;
    }

    .no-print {
      display: none;
    }
  }

  .App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }

  .header {
    position: sticky;
    top: 0;
    z-index: 999;
  }

  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }

  main {
    display: flex;
    min-height: 80vh;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  nav {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        display: inline-block;
        margin: 20px;

        a {
          color: black;
          text-decoration: none;

          &:hover,
          &:active {
            text-decoration: underline;
            color: silver;
          }
        }
      }
    }
  }
}