@use "sass:math";

.app-title-animation {
  $logo-color: "white";
  $smoke-color: "whitesmoke";

  align-items: center;
  align-content: center;
  backface-visibility: hidden;
  background: transparent;
  color: transparent;
  direction: ltr;
  font-family: Orbitron, sans-serif;
  font-size: 2.5rem;

  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  width: 133px;

  .app-title {
    color: #{$logo-color};
    direction: ltr;
    left: 0;
    position: absolute;
    top: 0;
  }

  .app-title-smoke {
    white-space: nowrap;
  }

  span {
    animation: smoke 12s 1s both infinite;
    direction: ltr;
    display: inline-block;
    text-shadow: 0 0 0 #{$smoke-color};
    unicode-bidi: bidi-override;
  }

  span:nth-child(even) {
    animation-name: smoke-mirror;
  }

  @keyframes smoke {
    60% {
      text-shadow: 0 0 40px #{$smoke-color};
    }
    to {
      transform: translate3d(15rem, -8rem, 0) rotate(-40deg) skewX(70deg)
        scale(1.5);
      text-shadow: 0 0 20px #{$smoke-color};
      opacity: 0;
    }
  }

  @keyframes smoke-mirror {
    60% {
      text-shadow: 0 0 40px #{$smoke-color};
    }
    to {
      transform: translate3d(18rem, -8rem, 0) rotate(-40deg) skewX(-70deg)
        scale(2);
      text-shadow: 0 0 20px #{$smoke-color};
      opacity: 0;
    }
  }

  @for $item from 1 through 21 {
    span:nth-of-type(#{$item}) {
      animation-delay: #{(3 + math.div($item, 10))}s;
    }
  }
}
