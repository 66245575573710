.ui-table {
  color: black;

  table {
    tr {
      td {
        position: relative;
      }
    }
  }

  [class^="MUIDataTable"] {
    color: black !important;
    font-size: 1rem;
    svg {
      fill: black;
    }
  }

  .MuiToolbar-root {
    justify-content: space-between !important;
  }

  .MuiGrid-root {
    padding: 0;

    .MuiGrid-container {
      grid-auto-rows: 1fr;
    }

    .MuiGrid-item {
      min-height: 20rem;

      .MuiPaper-root {
        height: 100%;
        overflow: hidden;
      }
    }
  }

  .map-so-wrapper {
    // make map containers adhere to 2:1 aspect ratio
    width: 100%;
    height: 100%;
    min-height: 100%;

    padding: 0;
    position: relative;

    .view-so-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}

.ui-table-root {
  .MuiTablePagination-displayedRows {
    display: block !important;
  }
}

.dark-mode .MuiTablePagination-selectLabel {
  color: white;
}

.dark-mode .MuiTypography-root {
  color: white;
}

.dark-mode .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  color: white !important;
}

.dark-mode .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon {
  color: white !important;
}
.light-mode .MuiTablePagination-selectLabel {
  color: black;
}

.light-mode .MuiTypography-root {
  color: black;
}
