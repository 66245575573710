//USAGE :
// @use "themeColors.scss" as variableName;
/*color: variableName.$info;*/

//@import "themeColors.scss" ;
/*color: $info;*/

//Use for background Colors
$warning: #ffc039; //sunglow
$info: #154b7e; //cathams-blue
$success: #157e48; //jewel
$error: #7e1516; //falu-red

//Legacy Colors (Previously e.g. SeekOpsBlue)
$info-light: #098beb; //azure-radiance
$info-dark: #0b3ade; //science-blue
$dark-blue: #1e2336; //mirage
$light-black: #333333; //mine-shaft
$dark: #000000;
$white: #ffffff;
