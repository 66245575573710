$titleBarBackground: "rgba(0, 0, 0, 0.9)";

:root {
  .menu-app-bar {
    background-color: transparent;
    box-shadow: none;
    width: 100%;

    header {
      background-color: #{$titleBarBackground};

      .tool-bar {
        align-items: center;
        align-content: center;
        justify-content: space-between;

        a {
          display: inline-flex;
        }

        svg {
          fill: white;
          margin: 0 auto;
        }
      }
    }
  }
}
