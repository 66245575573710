$warning: #ffc039; //sunglow
$info: #154b7e; //cathams-blue
$success: #157e48; //jewel
$error: #7e1516; //falu-red
$dark: #000000; //black
$white: white;

.chart-view {
  .grid-wrapper {
    .MuiGrid-root {
      .MuiGrid-container {
        grid-auto-rows: 1fr;
      }

      .MuiPaper-root {
        height: 100%;
      }
    }
  }

  .main-svg {
    .g-gtitle text {
      white-space: normal !important;
    }
  }

  @media print {
    @page {
      size: landscape;
      margin: 25mm 25mm 25mm 25mm;
    }
    .grid-wrapper {
      div {
        box-shadow: none !important;
        display: block;
      }
    }
  }
}

.chart-no-data {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
}

//CSS Applies only to ChartViewType scattersWithSlider
.charts-sliders-view {
  .chips-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 2rem;
    padding-bottom: 2rem;

    .chip {
      padding-left: 1rem;
      padding-right: 1rem;
      .active {
        background-color: #0b3ade;
        color: $white;
      }
    }
  }

  .MuiGrid-root {
    .MuiGrid-item {
      min-height: 0;
    }
    .bottom-drawer-header {
      .title {
        padding-left: 7rem;
        align-self: center;
      }
      .float-right {
        float: right;
      }
    }
  }

  .slider {
    padding-bottom: 1rem;
    .paper-slider {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .values-container {
      display: flex;
      .values {
        align-self: center;
      }
    }
  }
  //Slider positioning is left styleless
  //that way parent component has complete control over looks
  .bottom-drawer-content {
    .plots {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .add-range {
      padding-top: 2rem;
      padding-bottom: 2rem;
      padding-left: 1rem;
    }
  }
}
