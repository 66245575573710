$breakpoints: (
  "small": 767px,
  "medium": 992px,
  "large": 1200px,
);

@mixin respond-to($breakpoint) {
  // Retrieves the value from the key
  $value: map-get($breakpoints, $breakpoint);

  // If the key exists in the map
  @if $value != null {
    // Prints a media query based on the value
    @media (min-width: $value) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

.login-view {
  background-color: black;
  background: linear-gradient(
    270deg,
    #000000,
    #000000,
    #222222,
    #444444,
    #222222,
    #000000,
    #000000
  );
  background-size: 700% 700%;

  -webkit-animation: AnimationName 6s linear infinite;
  -moz-animation: AnimationName 6s linear infinite;
  animation: AnimationName 6s linear infinite;

  @-webkit-keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @-moz-keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  @keyframes AnimationName {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  position: relative;
  display: flex;
  flex-direction: row;

  .login-splash {
    height: 100vh;
    display: flex;
    width: 100vw;
    background-position: center bottom;
    background-size: cover;
    position: relative;

    opacity: 0.75;
  }
}

.login-welcome {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;

  .notice {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    white-space: nowrap;
    margin-bottom: 1rem;
    svg {
      margin: 0 0.5rem;
    }
  }

  .user-name {
    .MuiInputAdornment-root {
      padding: 0 0.5rem;
    }
  }
}
